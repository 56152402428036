/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Avatar, TextField, Typography } from "@mui/material";
import { getUsers } from "@services/Network";
import {
  ModelPerson,
  ModelUser,
  useAdminConnectionGroupGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useMemo, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { CalendarAttendee } from "./CalendarAttendee";
import ShareWithIcon from "../../../../src/assets/icons/shareWithIcon.svg";
import { organizationAtom, organizationsAtom, profileAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { Container } from "@components/crud/Container";
import Grid from "@mui/system/Unstable_Grid/Grid";
import ClearIcon from "@mui/icons-material/Clear";
import { CalendarConnectionGroupSearch } from "./CalendarConnectionGroupSearch";
import TeamIcon from "../../../../src/assets/icons/teamIcon.svg";
import personGroupIcon from "../../../../src/assets/icons/personGroupIcon.svg";

export const ShareWith = (props: {
  name: string;
  control: Control<any, any>;
  required: boolean | undefined;
  disabled?: boolean;
  setPersons?: () => void;
  persons: ModelPerson[] | ModelUser[];
  setConnectionGroups?: () => void;
  connectionGroups?: any;
  onChange?: (arg0: any) => void;
  action?;
}) => {
  const orgId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const org = organizations.find((org) => org.organizationId === orgId);
  const loggedInUser = useRecoilValue(profileAtom);
  const [personAddMenu, setPersonAddMenu] = useState(false);
  const [personSearch, setPersonSearch] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { data: personResults, isFetching: isFetchingUsers } = getUsers(
    { textSearch: encodeURIComponent(personSearch), organizationId: orgId },
    { staleTime: Infinity, enabled: !!personSearch?.length }
  );
  const { data: connectionGroupResults } = useAdminConnectionGroupGet({
    organizationId: orgId!,
    ...(personSearch && {
      textSearch: personSearch
    })
  });

  const filteredPersonResults = personResults.filter(
    (person) => person.personId !== loggedInUser!.personId
  );

  const connectionGroupOptions = useMemo(() => {
    const trainingProgram =
      connectionGroupResults?.data.programs &&
      connectionGroupResults.data.programs.map((program) => ({
        name: program.name,
        id: program.programId,
        description: "Training Program",
        orgOwned: !!program.organizationId,
        kind: "TrainingProgram"
      }));

    const trainingProgramPersonGroups =
      connectionGroupResults?.data.programs &&
      connectionGroupResults.data.programs.flatMap(
        (program) =>
          program.personGroups &&
          program.personGroups.map((group) => ({
            name: group.name,
            description: `${program.name} > ${group.name}`,
            id: group.groupId,
            orgOwned: !!program.organizationId,
            kind: "ConnectionGroup"
          }))
      );

    const team =
      connectionGroupResults?.data.teams &&
      connectionGroupResults.data.teams.map((team) => ({
        name: team.name,
        id: team.teamId,
        description: "Team",
        orgOwned: !!team.organizationId,
        kind: "Team"
      }));

    const teamPersonGroups =
      connectionGroupResults?.data.teams &&
      connectionGroupResults.data.teams.flatMap(
        (team) =>
          team.personGroups &&
          team.personGroups.map((group) => ({
            name: group.name,
            description: `${team.name} > ${group.name}`,
            id: group.groupId,
            orgOwned: !!team.organizationId,
            kind: "ConnectionGroup"
          }))
      );

    return [
      ...(trainingProgram || []),
      ...(trainingProgramPersonGroups || []),
      ...(team || []),
      ...(teamPersonGroups || [])
    ];
  }, [connectionGroupResults?.data]);

  const personOptions = [...filteredPersonResults, null];
  const handleHover = (index: number | null) => {
    setHoveredIndex(index);
  };
  const personAvatar = (person, index?) => {
    return (
      <Container>
        <Grid container alignItems="center">
          <Grid>
            <Avatar
              sx={{
                bgcolor: "#" + person.colorCode,
                color: "#fff !important",
                padding: "14px",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
                textAlign: "center"
              }}
              variant="square"
            >
              {`${person.firstName} ${person.lastName}`
                .split(" ")
                .map((name) => name[0])
                .join("")
                .toUpperCase()}
            </Avatar>
          </Grid>

          <Grid
            maxWidth={"fit-content"}
            onMouseEnter={() => handleHover(index)}
            onMouseLeave={() => handleHover(null)}
            marginLeft={"11px"}
            xs
            container
            direction="column"
          >
            {loggedInUser?.person?.personId == person.personId && (
              <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                {`${person.firstName} ${person.lastName}`}
                &nbsp;(you){" "}
                <span style={{ color: "#64748B", marginLeft: "5px" }}>
                  {" "}
                  &#x25cf; Creator
                </span>
              </Typography>
            )}
            {loggedInUser?.person?.personId != person.personId && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                  {`${person.firstName} ${person.lastName}`}
                </Typography>
                {loggedInUser?.person?.personId !== person.personId &&
                  hoveredIndex === index && (
                    <ClearIcon
                      style={{
                        color: "#64748B",
                        cursor: "pointer",
                        fontSize: "20px",
                        width: "200px"
                      }}
                      onClick={() => {
                        if (props.action == "create") {
                          //@ts-ignore
                          props.setPersons((prevPersons: ModelPerson[]) =>
                            prevPersons.filter(
                              (p: ModelPerson) => p.personId !== person.personId
                            )
                          );
                        }
                        if (props.action == "edit") {
                          //@ts-ignore
                          props.setPersons((prevPersons: ModelPerson[]) =>
                            prevPersons.filter((p: ModelPerson) =>
                              //@ts-ignore
                              p.person
                                ? //@ts-ignore
                                  p.person.personId !== person.personId
                                : p.personId !== person.personId
                            )
                          );
                        }
                      }}
                    />
                  )}
              </div>
            )}
            <Typography
              style={{ fontSize: "14px", fontWeight: 400, color: "#64748B" }}
            >
              {`${person.email}`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  };

  const connectionGroupAvatar = (connectionGroup, index?) => {
    let thumbnailContent;
    if (org) {
      if (
        (connectionGroup?.kind === "Team" ||
          connectionGroup?.kind === "TrainingProgram") &&
        !connectionGroup?.orgOwned
      ) {
        thumbnailContent = (
          <Avatar
            variant="square"
            style={{ width: "32px", height: "32px" }}
            src={TeamIcon}
            alt="Team Icon"
          />
        );
      } else if (
        connectionGroup?.kind === "ConnectionGroup" &&
        !connectionGroup?.orgOwned
      ) {
        thumbnailContent = (
          <Avatar
            variant="square"
            style={{ width: "32px", height: "32px" }}
            src={personGroupIcon}
            alt="Person Group Icon"
          />
        );
      } else if (org.avatarId) {
        thumbnailContent = (
          <Avatar
            variant="square"
            style={{ width: "32px", height: "32px" }}
            src={org.avatar!.baseUrl! + org.avatar!.path!}
            alt="Org Avatar"
          />
        );
      } else {
        if (connectionGroup?.kind === "ConnectionGroup") {
          thumbnailContent = (
            <Avatar
              variant="square"
              style={{ width: "32px", height: "32px" }}
              src={personGroupIcon}
              alt="Org Avatar"
            />
          );
        }
        if (
          connectionGroup?.kind === "Team" ||
          connectionGroup?.kind === "TrainingProgram"
        ) {
          thumbnailContent = (
            <Avatar
              variant="square"
              style={{ width: "32px", height: "32px" }}
              src={TeamIcon}
              alt="Org Avatar"
            />
          );
        }
      }
    }
    return (
      <Container>
        <Grid container alignItems="center">
          <Grid>{thumbnailContent}</Grid>

          <Grid
            maxWidth={"fit-content"}
            onMouseEnter={() => handleHover(index)}
            onMouseLeave={() => handleHover(null)}
            marginLeft={"11px"}
            xs
            container
            direction="column"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "200px"
              }}
            >
              <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                {`${connectionGroup.name}`}
              </Typography>
              {hoveredIndex === index && (
                <ClearIcon
                  style={{
                    color: "#64748B",
                    cursor: "pointer",
                    fontSize: "20px"
                  }}
                  onClick={() => {
                    //@ts-ignore
                    props.setConnectionGroups((prevConnection) =>
                      prevConnection.filter((p) => p.id !== connectionGroup.id)
                    );
                  }}
                />
              )}
            </div>
            <Typography
              style={{ fontSize: "14px", fontWeight: 400, color: "#64748B" }}
            >
              {`${connectionGroup.description}`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px"
        }}
      >
        <>
          <img src={ShareWithIcon} />
          <Controller
            control={props.control}
            name={props.name}
            render={() => (
              <Autocomplete
                data-testId="shareWithInput"
                open={personAddMenu}
                clearOnBlur={false}
                sx={{ minWidth: "525px" }}
                onOpen={() => setPersonAddMenu(true)}
                onClose={() => setPersonAddMenu(false)}
                getOptionLabel={(option) =>
                  option?.personId
                    ? `${option.firstName} ${option.lastName}`
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="shareWith"
                    style={{ marginLeft: "14px" }}
                    hiddenLabel
                    variant="standard"
                    placeholder="Share with"
                    onChange={(e) => setPersonSearch(e.target.value)}
                  />
                )}
                filterOptions={(options) =>
                  personAddMenu && !isFetchingUsers
                    ? options.filter(
                        (o) =>
                          !props.persons?.find(
                            (p) => p.personId === o?.personId
                          ) &&
                          !props.connectionGroups?.find((c) => c.id === o?.id)
                      )
                    : []
                }
                renderOption={(prop, option) => {
                  if (option?.personId) {
                    return (
                      <CalendarAttendee
                        {...prop}
                        key={option.personId}
                        //@ts-ignore
                        person={option}
                        onClick={() => {
                          setPersonAddMenu(false);
                          if (props.action == "create") {
                            //@ts-ignore
                            props.setPersons((prevPersons: ModelPerson) => [
                              //@ts-ignore
                              ...prevPersons,
                              option
                            ]);
                          }
                          if (props.action == "edit") {
                            const person = {};
                            person["person"] = option;
                            //@ts-ignore
                            props.setPersons((prevPersons: ModelPerson) => [
                              //@ts-ignore
                              ...prevPersons,
                              person
                            ]);
                            if (props.onChange) props.onChange(props.persons);
                          }
                        }}
                      />
                    );
                  }
                  if (option?.id) {
                    return (
                      <CalendarConnectionGroupSearch
                        {...prop}
                        key={option.id}
                        //@ts-ignore
                        connectionGroup={option}
                        onClick={() => {
                          setPersonAddMenu(false);
                          //@ts-ignore
                          props.setConnectionGroups((prevConnection) => [
                            //@ts-ignore
                            ...prevConnection,
                            option
                          ]);
                          if (props.onChange)
                            props.onChange(props.connectionGroups);
                        }}
                      />
                    );
                  }
                }}
                options={personOptions.concat(connectionGroupOptions) || []}
                loading={isFetchingUsers}
                loadingText="Loading..."
              />
            )}
          />
        </>
      </div>
      <div
        style={{
          marginLeft: "35px",
          marginTop: "10px",
          maxHeight: "160px",
          overflow: "auto"
        }}
      >
        {props.action === "create" && (
          <div>
            <div style={{ color: "#000" }} data-testid="creator-shareWith">
              {personAvatar(loggedInUser?.person)}
            </div>
            {props.persons.map((person, index) => (
              <div
                style={{ marginTop: "10px" }}
                key={index}
                data-testId={`shareWith-${person.personId}`}
              >
                {personAvatar(person, index)}
              </div>
            ))}
            {props.connectionGroups?.map((group, index) => (
              <div
                style={{ marginTop: "10px" }}
                key={index}
                data-testId={`shareWith-${group.id}`}
              >
                {connectionGroupAvatar(group, index)}
              </div>
            ))}
          </div>
        )}
        {props.action === "edit" && (
          <div>
            {props.persons
              ?.sort((a, b) => {
                if (a.person!.personId === loggedInUser?.person?.personId)
                  return -1;
                if (b.person!.personId === loggedInUser?.person?.personId)
                  return 1;
                return 0;
              })
              .map((person, index) => (
                <div
                  style={{ marginTop: "10px" }}
                  key={index}
                  data-testid={`share-with-${index}`}
                >
                  {personAvatar(person.person, index)}
                </div>
              ))}
            {props.connectionGroups?.map((group, index) => (
              <div style={{ marginTop: "10px" }} key={index}>
                {connectionGroupAvatar(group, index)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

ShareWith.defaultProps = {
  required: false,
  disabled: false,
  action: "create"
};
