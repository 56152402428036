import {
  Autocomplete,
  Backdrop,
  Box,
  Container,
  InputAdornment,
  TextField,
  Typography,
  styled
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colors from "theme/colors";
import { Controller, useFormContext } from "react-hook-form";
import Grid from "@mui/system/Unstable_Grid";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { Footer } from "@components/crud/Footer";
import { FormInput } from "@components/FormInput";
import { Search } from "@mui/icons-material";
import {
  useAdminAlbumGet,
  useAdminLiveStreamStreamIdClipTagGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { organizationAtom, profileAtom } from "@recoil/auth";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "125px",
  width: "100%",
  maxWidth: "650px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  },
  "& .footer": {
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px"
  }
}));

const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});

export const SaveNewClipModal = ({
  onClose,
  onSave,
  isLoading,
  streamDetails
}: {
  onClose: () => void;
  onSave: () => void;
  isLoading: boolean;
  streamDetails: {
    streamId: string;
    sportId: string;
    clipId?: string;
    url?: string;
    teamProgramId: string | undefined;
    duration: number;
    startTime?: number;
    endTime?: number;
  };
}) => {
  const user = useRecoilValue(profileAtom);
  const organizationId = useRecoilValue(organizationAtom);
  const [albumSearch, setAlbumSearch] = useState<string | undefined>(undefined);

  const {
    control,
    setValue,
    formState: { isValid }
  } = useFormContext();

  const { data: presetTags } = useAdminLiveStreamStreamIdClipTagGet(
    streamDetails.streamId
  );

  const tagsOptions = useMemo(() => {
    return [
      ...(presetTags?.data?.presetTags || [])
        .sort((a, b) => a.tagId?.localeCompare(b.tagId ?? "") ?? 0)
        .map((tag) => ({
          label: tag.tagId,
          value: tag.tagId,
          type: "Pre-set Tags"
        })),
      ...(presetTags?.data?.memberTags || [])
        .sort((a, b) => a.tagId?.localeCompare(b.tagId ?? "") ?? 0)
        .map((tag) => ({
          label: tag.tagId,
          value: tag.tagId,
          type: "Team Members"
        })),
      ...(presetTags?.data?.personGroupTags || [])
        .sort((a, b) => a.tagId?.localeCompare(b.tagId ?? "") ?? 0)
        .map((tag) => ({
          label: tag.tagId,
          value: tag.tagId,
          type: "Program Person Groups"
        }))
    ];
  }, [presetTags]);

  const { data: albums, isLoading: isAlbumLoading } = useAdminAlbumGet({
    organizationId: organizationId!,
    ...(albumSearch && albumSearch.length > 0
      ? { textSearch: albumSearch }
      : {}),
    contributorId: user?.userId
  });

  const albumsOptions = useMemo(() => {
    if (albums?.data) {
      return albums.data.map((album) => ({
        label: album.name,
        value: album.albumId
      }));
    }
    return [];
  }, [albums]);

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <Container>
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid sx={{ maxWidth: "88%" }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  Save New Clip
                </Typography>
              </Grid>
              <Grid>
                <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Grid container spacing={3} padding="24px" zIndex="1000">
            <Grid xs={12}>
              <FormInput
                control={control}
                type="text"
                name="title"
                label="Title"
                required
                rules={{
                  required: "Title is required"
                }}
              />
            </Grid>
            <Grid xs={12}>
              <StyledFormLabel>
                <Typography display="inline" variant="formLabel">
                  Tags
                </Typography>
              </StyledFormLabel>
              <Autocomplete
                freeSolo
                disableClearable={true}
                multiple
                groupBy={(option) => option.type}
                options={tagsOptions}
                filterSelectedOptions
                renderInput={(params) => {
                  return <TextField {...params} />;
                }}
                noOptionsText="No tags found"
                loadingText="Loading..."
                loading
                onChange={(e, value) => {
                  setValue("tags", value?.map((v) => v));
                }}
              />
            </Grid>
            <Grid xs={12}>
              <StyledFormLabel>
                <Typography display="inline" variant="formLabel">
                  Album
                </Typography>
              </StyledFormLabel>
              <Controller
                name="album"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    freeSolo
                    value={field.value}
                    options={albumsOptions}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Typography>{option.label}</Typography>
                      </li>
                    )}
                    noOptionsText="No albums found"
                    loadingText="Loading..."
                    getOptionLabel={(option) => option.label}
                    loading={isAlbumLoading}
                    renderInput={(params) => {
                      if (Array.isArray(params.InputProps.startAdornment)) {
                        params.InputProps.startAdornment.splice(
                          0,
                          0,
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        );
                      } else {
                        params.InputProps.startAdornment = (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        );
                      }
                      return (
                        <TextField
                          {...params}
                          onChange={(e) => {
                            setAlbumSearch(e.target.value);
                          }}
                        />
                      );
                    }}
                    onChange={(e, value) => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Footer
            cancelBtnClick={() => {
              ["title", "tags", "album"].forEach((field) => {
                setValue(field, undefined);
              });
              onClose();
            }}
            saveBtnClick={onSave}
            isDisabled={!isValid || isLoading}
            isLoading={isLoading}
          />
        </StyledBox>
      </Container>
    </Backdrop>
  );
};
