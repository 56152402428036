/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, styled, IconButton, Typography, Slider } from "@mui/material";
import {
  ModelMedia,
  ModelStream,
  ModelStreamScore,
  ObjectAny,
  RoleType
} from "@sportsgravyengineering/sg-api-react-sdk";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReactPlayer from "react-player";
import { useEffect, useRef, useState } from "react";
import { LoadingSpinner } from "@components/LoadingSpinner";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import Forward5Icon from "@mui/icons-material/Forward5";
import Replay5Icon from "@mui/icons-material/Replay5";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import { organizationAtom, profileAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { hasPermission } from "@services/Casbin";
import { LivestreamClipList } from "./clips/LivestreamClipList";
import Grid from "@mui/system/Unstable_Grid";
import { FormProvider, useForm } from "react-hook-form";
import { LivestreamClipCreate } from "./clips/LivestreamClipCreate";
import { CropIcon } from "@components/Icons";
import { LivestreamCropClip } from "./clips/LivestreamCropClip";
import { MAX_CLIP_DURATION } from "@utils/constants";

type StreamScore = {
  scores: ModelStreamScore[];
  timestamp: number;
};

type IntervalCount = {
  intervalCount: number;
  isHomeTeam: boolean | null;
  timestamp: number;
};

const convertToSeconds = (time: string): number => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

const formatTime = (seconds: number): string => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
};

export const calculateEndTime = (
  startTime: number,
  maxTime: number,
  endDuration = 30
): number => {
  return Math.min(startTime + endDuration, maxTime);
};

const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: "#000",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "60vh",
  overflow: "hidden",
  borderRadius: "5px",
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  },
  ".play-pause-icon button": {
    background: "#000",
    opacity: 0.5
  }
}));

const TeamName = styled(Typography)({
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "16px",
  color: "#fff"
});

const TeamScore = styled(Typography)({
  fontWeight: 700,
  fontSize: "13px",
  lineHeight: "16px",
  color: "#fff"
});

const SliderDiv = styled("div")({
  position: "relative",
  width: "100%"
});

export const CompletedLivestreamWatch = ({
  liveStream,
  defaultClip,
  showClipsList = true,
  setCropClip
}: {
  liveStream: ModelStream;
  defaultClip?: ModelMedia;
  showClipsList?: boolean;
  setCropClip?: (value: boolean) => void;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const user = useRecoilValue(profileAtom);
  const [permissions, setPermissions] = useState({ clip: false, crop: false });
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [scores, setScores] = useState<StreamScore[]>([]);
  const [intervals, setIntervals] = useState<IntervalCount[]>([]);
  const [score, setScore] = useState<StreamScore | undefined>(undefined);
  const [interval, setInterval] = useState<IntervalCount | undefined>(
    undefined
  );
  const [isMainLsPlaying, setIsMainLsPlaying] = useState<boolean>(true);
  const [isHome] = useState<boolean>(liveStream?.isHomeTeam as boolean);
  const [duration, setDuration] = useState<number>(0);
  const [currentDuration, setCurrentDuration] = useState<number>(0);
  const playerRef = useRef<ReactPlayer>(null);
  const [playing, setPlaying] = useState(true);
  const [isScoreExpanded, setIsScoreExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [muted, setMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const handlePlayPause = () => setPlaying(!playing);
  const toggleScoreExpand = () => setIsScoreExpanded(!isScoreExpanded);
  const [showControls, setShowControls] = useState(true);
  const [currentPlayingEnded, setCurrentPlayingEnded] = useState(false);
  const [currentClipPlaying, setCurrentClipPlaying] = useState<
    ModelMedia | undefined
  >(undefined);
  const [isDragging, setIsDragging] = useState(false);
  const [clipData, setClipData] = useState<
    | {
        streamId: string;
        sportId: string;
        clipId?: string;
        url?: string;
        teamProgramId: string | undefined;
        duration: number;
        startTime?: number;
        endTime?: number;
      }
    | undefined
  >(undefined);
  const [clipView, setClipView] = useState(false);
  const [clipCreate, setClipCreate] = useState(false);
  const [clipCrop, setClipCrop] = useState(false);
  const [pausedAt, setPausedAt] = useState<number | null>(null);
  const form = useForm({
    mode: "all",
    defaultValues: {
      filterTags: ["Tags"],
      title: "",
      startTime: 0,
      endTime: 0
    }
  });

  useEffect(() => {
    if (
      liveStream?.metadata &&
      (liveStream?.metadata as { scores: ModelStreamScore[] })?.scores
    ) {
      const modifiedScores = (
        liveStream?.metadata as { scores: ModelStreamScore[] }
      )?.scores
        .map((score: any) => ({
          ...score,
          timestamp: convertToSeconds(score.timestamp)
        }))
        .sort((a, b) => b.timestamp - a.timestamp);
      setScores(modifiedScores);
    }
    if (
      liveStream?.metadata &&
      (liveStream?.metadata as { intervals: ObjectAny[] })?.intervals
    ) {
      const modifiedIntervals = (
        liveStream?.metadata as { intervals: ObjectAny[] }
      )?.intervals
        .map((interval: any) => ({
          ...interval,
          timestamp: convertToSeconds(interval?.timestamp)
        }))
        .sort((a, b) => b.timestamp - a.timestamp);
      setIntervals(modifiedIntervals);
    }
    setClipData({
      ...clipData!,
      streamId: liveStream?.streamId as string
    });
  }, [liveStream]);

  useEffect(() => {
    if (scores.length > 0) {
      const currentScore = scores.find(
        (score) => score.timestamp <= currentDuration
      );
      setScore(currentScore);
    }
    if (intervals.length > 0) {
      const currentInterval = intervals.find(
        (interval) => interval.timestamp <= currentDuration
      );
      setInterval(currentInterval);
    }
  }, [currentDuration]);

  const handleMuteUnmute = () => setMuted(!muted);
  const handleFullscreen = () => {
    const elem = document.getElementById("livestream-player");
    if (elem) {
      if (!isFullscreen && elem.requestFullscreen) {
        elem.requestFullscreen();
      }
      if (isFullscreen && document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };
  useEffect(() => {
    const elem = document.getElementById("livestream-player");
    if (elem) {
      elem.addEventListener("fullscreenchange", () => {
        setIsFullscreen(!!document.fullscreenElement);
      });
    }
  }, []);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setIsDragging(true);
    if (typeof newValue === "number") {
      const seekTo = newValue as number;
      setCurrentDuration(seekTo);
      if (playerRef.current) {
        playerRef.current.seekTo(seekTo, "seconds");
      }
      if (clipCreate || clipCrop) {
        setCurrentDuration(seekTo);
        setClipData({
          ...clipData!,
          startTime: Number(seekTo.toFixed(0)) as number,
          endTime: calculateEndTime(
            Number(seekTo.toFixed(0)),
            Number(clipData?.duration.toFixed(0))
          )
        });
      }
    }
    if (typeof newValue === "object") {
      // eslint-disable-next-line prefer-const
      let [start, end] = newValue as number[];
      setCurrentDuration(start);
      if (playerRef.current) {
        playerRef.current.seekTo(start + 1, "seconds");
      }

      if (end - start > MAX_CLIP_DURATION) {
        end = start + MAX_CLIP_DURATION;
      }

      if (clipCreate || clipCrop) {
        setClipData({
          ...clipData!,
          startTime: Number(start.toFixed(0)) as number,
          endTime: Number(end.toFixed(0)) as number
        });
      }
    }
  };
  const seek5Seconds = (forward: boolean) => {
    const newTime = forward
      ? Math.min(duration, currentDuration + 5)
      : Math.max(0, currentDuration - 5);
    setCurrentDuration(newTime);
    if (playerRef.current) {
      playerRef.current.seekTo(newTime, "seconds");
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowControls(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  const handleMouseMove = () => {
    setShowControls(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setShowControls(false);
    }, 3000);
  };

  useEffect(() => {
    if (liveStream) {
      const checkPermission = async (
        roleType: RoleType,
        roleId,
        permissionId,
        permission
      ) => {
        const res = await hasPermission(
          roleType,
          roleId,
          permissionId as string,
          permission as string
        );
        return res;
      };
      const roles = user?.roles?.filter(
        (role) => role.organizationId === organizationId
      );
      const fetchPermissions = async () => {
        const clip = await checkPermission(
          "ORGANIZATION",
          organizationId,
          "live-streaming.clip-stream",
          "ON"
        );
        const organizationPost = await checkPermission(
          organizationId ? "ORGANIZATION" : "SYSTEM",
          organizationId || "*",
          "organization.post",
          "ON"
        );
        const teamPost1 = await checkPermission(
          "TEAM",
          liveStream?.teamId,
          "team.associated-post",
          "ON"
        );
        const teamPost2 = await checkPermission(
          "ORGANIZATION",
          organizationId,
          "team.all-social",
          "ON"
        );
        const programPost1 = await checkPermission(
          "TRAINING_PROGRAM",
          liveStream?.programId,
          "training-programs.associated-post",
          "ON"
        );
        const programPost2 = await checkPermission(
          "ORGANIZATION",
          organizationId,
          "training-programs.all-social",
          "ON"
        );
        setPermissions({
          clip:
            (clip &&
              (organizationPost ||
                teamPost1 ||
                teamPost2 ||
                programPost1 ||
                programPost2)) ||
            (roles?.some((role) => role.role?.alias === "ADMIN") as boolean),
          crop:
            currentClipPlaying?.createdById == user?.userId ||
            (roles?.some((role) => role.role?.alias === "ADMIN") as boolean)
        });
      };
      fetchPermissions();
    }
  }, [liveStream, currentClipPlaying]);

  useEffect(() => {
    if (currentClipPlaying && playerRef.current) {
      setPlaying(true);
    }
  }, [currentClipPlaying]);

  const handleClipViewClick = () => {
    setClipView(true);
    if (!pausedAt) setPausedAt(currentDuration);
    setIsMainLsPlaying(false);
  };

  const handleClipCreateClick = () => {
    setClipData({
      ...clipData!,
      streamId: liveStream.streamId as string,
      startTime: Number(currentDuration.toFixed(0)),
      endTime: calculateEndTime(
        Number(currentDuration.toFixed(0)),
        Number(clipData?.duration.toFixed(0))
      )
    });
    setClipCreate(true);
    if (isFullscreen) handleFullscreen();
    setPausedAt(currentDuration);
    setIsMainLsPlaying(false);
  };

  const handleClipCropClick = () => {
    setClipData({
      ...clipData!,
      url: currentClipPlaying?.baseUrl
        ? currentClipPlaying.baseUrl + currentClipPlaying.path
        : undefined,
      clipId: currentClipPlaying?.mediaId,
      startTime: Number(currentDuration.toFixed(0)),
      endTime: calculateEndTime(
        Number(currentDuration.toFixed(0)),
        Number(duration.toFixed(0))
      ),
      duration: Number(duration.toFixed(0))
    });
    if (isFullscreen) handleFullscreen();
    setClipCrop(true);
    setCropClip && setCropClip(true);
  };

  const handleWatchFullLs = () => {
    setIsMainLsPlaying(true);
    setClipView(false);
    setClipCreate(false);
    setCurrentClipPlaying(undefined);
    if (pausedAt) {
      setTimeout(() => {
        if (playerRef.current) playerRef.current.seekTo(pausedAt, "seconds");
        setPausedAt(null);
      }, 200);
    }
  };

  useEffect(() => {
    if (defaultClip) {
      setClipView(true);
      setIsMainLsPlaying(false);
      setCurrentClipPlaying(defaultClip);
      form.setValue(
        "title",
        (defaultClip.metadata as { title?: string })?.title as string
      );
    }
  }, [defaultClip]);

  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid xs={!clipCreate && !clipCrop && !showClipsList ? 12 : 9}>
        <StyledBox
          id="livestream-player"
          onMouseMove={handleMouseMove}
          onMouseLeave={() => setShowControls(false)}
        >
          <>
            <div
              className="react-player"
              onClick={handlePlayPause}
              style={{ width: "100%", height: "100%" }}
            >
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                  }}
                >
                  <LoadingSpinner size={50} />
                </div>
              )}
              <ReactPlayer
                ref={playerRef}
                playing={playing}
                muted={muted}
                height="100%"
                width="100%"
                url={
                  currentClipPlaying
                    ? currentClipPlaying.baseUrl &&
                      currentClipPlaying.baseUrl + currentClipPlaying.path
                    : liveStream?.vod?.baseUrl && liveStream?.vod?.path
                    ? liveStream?.vod?.baseUrl + liveStream?.vod?.path
                    : ""
                }
                onStart={() => {
                  setIsLoading(false);
                  setCurrentPlayingEnded(false);
                }}
                onBuffer={() => setIsLoading(true)}
                onBufferEnd={() => setIsLoading(false)}
                onEnded={() => {
                  setPlaying(false);
                  setCurrentPlayingEnded(true);
                }}
                onDuration={(duration) => {
                  setDuration(Number((duration - 1).toFixed(0)));
                  if (isMainLsPlaying)
                    setClipData({
                      sportId: liveStream?.sportId as string,
                      url:
                        liveStream?.vod?.baseUrl && liveStream?.vod?.path
                          ? liveStream?.vod?.baseUrl + liveStream?.vod?.path
                          : undefined,
                      streamId: liveStream.streamId as string,
                      teamProgramId: liveStream.teamId || liveStream.programId,
                      duration: Number(duration.toFixed(0))
                    });
                }}
                progressInterval={500}
                onProgress={(state) =>
                  setCurrentDuration(Number(state.playedSeconds))
                }
              />
            </div>
            {!isLoading && (
              <div
                className="play-pause-icon"
                style={{
                  opacity: showControls || !playing ? 1 : 0,
                  transition: "opacity 0.6s",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }}
              >
                {playing && !clipCreate && !clipCrop && (
                  <IconButton
                    style={{ marginRight: "35px" }}
                    onClick={() => seek5Seconds(false)}
                  >
                    <Replay5Icon
                      sx={{ fill: "#fff", height: "40px", width: "40px" }}
                    />
                  </IconButton>
                )}

                <IconButton onClick={handlePlayPause}>
                  {playing ? (
                    <PauseIcon
                      sx={{ fill: "#fff", height: "50px", width: "50px" }}
                    />
                  ) : (
                    <PlayArrowIcon
                      sx={{ fill: "#fff", height: "50px", width: "50px" }}
                    />
                  )}
                </IconButton>
                {playing && !clipCreate && !clipCrop && (
                  <IconButton
                    style={{ marginLeft: "35px" }}
                    onClick={() => seek5Seconds(true)}
                  >
                    <Forward5Icon
                      sx={{ fill: "#fff", height: "40px", width: "40px" }}
                    />
                  </IconButton>
                )}
              </div>
            )}
            {(liveStream?.contestType == "GAME" ||
              liveStream?.contestType == "SCRIMMAGE") &&
              liveStream?.scores &&
              liveStream?.scores?.length > 0 &&
              isMainLsPlaying &&
              scores && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "20px",
                    left: "20px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    background: "#5f5f5f"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          background: "#E82C2C",
                          width: "100%",
                          padding: "4px 8px",
                          textAlign: "center",
                          fontWeight: "700",
                          height: "30px",
                          borderTopLeftRadius: "8px",
                          color: "#fff"
                        }}
                      >
                        {`${
                          liveStream.team?.gender == "MALE"
                            ? liveStream.sport?.organizations?.[0]
                                .maleIntervalAbbreviation
                            : liveStream.sport?.organizations?.[0]
                                .femaleIntervalAbbreviation
                        }${
                          !interval?.intervalCount
                            ? liveStream.intervalCount
                            : interval.intervalCount
                        }`}
                      </Box>
                      <Box
                        sx={{
                          background: "#2B337A",
                          width: "100%",
                          color: "#fff",
                          fontWeight: "700",
                          padding: "4px 8px",
                          textAlign: "center",
                          height: "30px",
                          borderBottomLeftRadius: "8px"
                        }}
                      >
                        {`H ${score?.scores?.[0]?.homeScore || 0} - ${
                          score?.scores?.[0]?.awayScore || 0
                        } A`}
                      </Box>
                    </Box>
                    {isScoreExpanded && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "0 8px",
                          rowGap: "10px"
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "space-between"
                          }}
                        >
                          <TeamName>
                            {isHome
                              ? liveStream.team?.name
                              : liveStream.opponent}{" "}
                            (H)
                          </TeamName>
                          <TeamScore>
                            {score?.scores?.[0]?.homeScore || 0}
                          </TeamScore>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "space-between"
                          }}
                        >
                          <TeamName>
                            {!isHome
                              ? liveStream.team?.name
                              : liveStream.opponent}{" "}
                            (A)
                          </TeamName>
                          <TeamScore>
                            {score?.scores?.[0]?.awayScore || 0}
                          </TeamScore>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      background: "#848484",
                      width: "20px",
                      height: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderTopRightRadius: "8px",
                      borderBottomRightRadius: "8px",
                      cursor: "pointer"
                    }}
                    onClick={toggleScoreExpand}
                  >
                    {isScoreExpanded ? (
                      <ChevronLeftIcon
                        sx={{
                          fill: "#fff",
                          height: "20px",
                          width: "120x"
                        }}
                      />
                    ) : (
                      <ChevronRightIcon
                        onClick={toggleScoreExpand}
                        sx={{
                          fill: "#fff",
                          height: "20px",
                          width: "20px"
                        }}
                      />
                    )}
                  </Box>
                </Box>
              )}

            <Box
              className="control-box"
              sx={{
                opacity: showControls ? 1 : 0,
                transition: "opacity 0.6s",
                position: "absolute",
                bottom: "15px",
                padding: "0 30px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                marginBottom: "20px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px"
                  }}
                >
                  <IconButton
                    onClick={handlePlayPause}
                    style={{ paddingLeft: 0 }}
                  >
                    {!playing ? (
                      <PlayArrowIcon sx={{ fill: "#fff" }} />
                    ) : (
                      <PauseIcon sx={{ fill: "#fff" }} />
                    )}
                  </IconButton>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: 600
                    }}
                  >
                    {formatTime(currentDuration)} / {formatTime(duration)}
                  </Typography>
                </Box>
                <Box>
                  {permissions.clip &&
                    !clipView &&
                    !clipCreate &&
                    duration >= 10 && (
                      <IconButton
                        onClick={handleClipCreateClick}
                        sx={{ color: "#fff" }}
                      >
                        <ContentCutIcon
                          sx={{
                            fill: "#fff",
                            width: "20px",
                            height: "20px"
                          }}
                        />
                      </IconButton>
                    )}
                  {permissions.clip &&
                    clipView &&
                    !clipCrop &&
                    duration >= 10 && (
                      <IconButton
                        onClick={handleClipCropClick}
                        sx={{ color: "#fff" }}
                      >
                        <CropIcon />
                      </IconButton>
                    )}
                  <IconButton onClick={handleMuteUnmute} sx={{ color: "#fff" }}>
                    {muted ? (
                      <VolumeOffIcon sx={{ fill: "#fff" }} />
                    ) : (
                      <VolumeUpIcon sx={{ fill: "#fff" }} />
                    )}
                  </IconButton>
                  {!clipCreate && !clipCrop && (
                    <IconButton
                      onClick={handleFullscreen}
                      sx={{ color: "#fff" }}
                    >
                      {isFullscreen ? (
                        <FullscreenExitIcon sx={{ fill: "#fff" }} />
                      ) : (
                        <FullscreenIcon sx={{ fill: "#fff" }} />
                      )}
                    </IconButton>
                  )}
                </Box>
              </Box>
              <SliderDiv>
                <Slider
                  value={
                    isMainLsPlaying || (!clipCreate && !clipCrop)
                      ? currentDuration
                      : ([clipData?.startTime, clipData?.endTime] as number[])
                  }
                  max={duration}
                  min={0}
                  onChange={handleSliderChange}
                  onChangeCommitted={() => setIsDragging(false)}
                  sx={{
                    position: "absolute",
                    width: "100%",
                    color: clipCrop || clipCreate ? "#fff" : "#e82c2c",
                    "& .MuiSlider-thumb": {
                      display:
                        clipCreate || clipCrop || isDragging ? "none" : "block",
                      backgroundColor: "#e82c2c",
                      height: 14,
                      width: 14,
                      opacity: 0,
                      transition: "opacity 0.3s, left 0.3s"
                    },
                    "&:hover .MuiSlider-thumb": {
                      opacity: 1
                    },
                    "& .MuiSlider-track": {
                      height: 4
                    },
                    "& .MuiSlider-rail": {
                      height: 4,
                      color: "#fff"
                    }
                  }}
                />
                {!isDragging && (clipCreate || clipCrop) && (
                  <Slider
                    className="secondary-slider"
                    value={currentDuration}
                    max={duration}
                    min={0}
                    disabled
                    sx={{
                      position: "absolute",
                      width: "100%",
                      "& .MuiSlider-thumb": {
                        backgroundColor: "#ff0000",
                        height: 14,
                        width: 14,
                        opacity: 1,
                        transition: "opacity 0.3s, left 0.5s"
                      },
                      "& .MuiSlider-track": {
                        display: "none"
                      },
                      "& .MuiSlider-rail": {
                        display: "none"
                      }
                    }}
                  />
                )}
              </SliderDiv>
            </Box>

            {(clipView || clipCreate) && !clipCrop && !defaultClip && (
              <Box
                sx={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  padding: "8px",
                  background: "#00000026",
                  color: "#fff",
                  cursor: "pointer",
                  fontWeight: 600
                }}
                onClick={handleWatchFullLs}
              >
                Watch Full Live Stream
              </Box>
            )}
          </>
        </StyledBox>
      </Grid>
      {clipCreate && (
        <Grid xs={3} paddingLeft="10px">
          <LivestreamClipCreate
            streamDetails={clipData!}
            onClose={handleWatchFullLs}
            playerRef={playerRef}
            setClipData={setClipData}
            setIsDragging={setIsDragging}
          />
        </Grid>
      )}
      <FormProvider {...form}>
        <Grid xs={3} paddingLeft="10px">
          {!clipCreate && !clipCrop && showClipsList && (
            <LivestreamClipList
              streamDetails={clipData!}
              handleClipViewClick={handleClipViewClick}
              setCurrentClipPlaying={setCurrentClipPlaying}
              currentClipPlaying={currentClipPlaying}
              handlePlayPause={handlePlayPause}
              isPlaying={playing}
              mainLsPlaying={isMainLsPlaying}
              currentPlayingEnded={currentPlayingEnded}
            />
          )}
          {clipCrop && (
            <LivestreamCropClip
              streamDetails={clipData!}
              onClose={() => {
                setClipCrop(false);
                setCropClip && setCropClip(false);
              }}
              playerRef={playerRef}
              setClipData={setClipData}
              setIsDragging={setIsDragging}
            />
          )}
        </Grid>
      </FormProvider>
    </Grid>
  );
};
