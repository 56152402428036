import { Close, Search } from "@mui/icons-material";
import {
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputProps,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Fragment } from "react";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const StyledInput = styled(Input)(() => ({
  width: "100%",
  height: "50px",
  borderRadius: "6px",
  border: "1px solid rgba(0, 0, 0, 0.23)",

  "&.MuiInputBase-adornedStart": {
    paddingLeft: "8px"
  },

  "&.MuiInputBase-root:before, &.MuiInputBase-root.MuiInput-focused:before": {
    borderBottom: "none"
  },

  "&.MuiInputBase-root:after": {
    borderBottom: "none"
  },

  "&.MuiInputBase-root:hover:before": {
    borderBottom: "none"
  },

  "&.MuiInputBase-root:hover:after": {
    borderBottom: "none"
  }
}));

export const SearchInput = ({
  placeholder,
  label = "",
  onChange,
  onBlur,
  required,
  InputProps,
  onClearClick,
  error,
  clearable,
  ...props
}: {
  placeholder: string;
  label?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  required: boolean;
  InputProps?: InputProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  clearable?: boolean;
  onClearClick?: () => void;
}) => {
  return (
    <Fragment>
      {label && (
        <StyledFormLabel required={required}>
          <Typography display="inline" variant="formLabel">
            {label}
          </Typography>
        </StyledFormLabel>
      )}
      <StyledInput
        {...InputProps}
        {...props}
        placeholder={placeholder}
        className="search-input"
        onChange={onChange}
        onBlur={onBlur || InputProps?.onBlur}
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
        endAdornment={
          clearable ? (
            <IconButton
              onClick={() => {
                if (onClearClick) onClearClick();
              }}
            >
              <Close />
            </IconButton>
          ) : undefined
        }
      />
      {error && (
        <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      )}
    </Fragment>
  );
};
