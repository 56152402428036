import { FormInput } from "@components/FormInput";
import { styled, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { ClipTimeFields } from "./ClipTimeFields";
import { SaveNewClipModal } from "./SaveNewClipModal";
import ReactPlayer from "react-player";
import { VideoFrames } from "./VideoFrames";
import {
  useAdminLiveStreamStreamIdClipClipIdPut,
  useAdminLiveStreamStreamIdClipPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";

const StyledButton = styled("div")`
  button {
    border: 2px solid #2b337a !important;
  }
`;

export const LivestreamCropClip = ({
  streamDetails,
  onClose,
  playerRef,
  setClipData,
  setIsDragging
}: {
  streamDetails: {
    streamId: string;
    sportId: string;
    clipId?: string;
    url?: string;
    teamProgramId: string | undefined;
    duration: number;
    startTime?: number;
    endTime?: number;
  };
  playerRef: React.RefObject<ReactPlayer>;
  onClose: () => void;
  setClipData: (data) => void;
  setIsDragging: (dragging: boolean) => void;
}) => {
  const {
    control,
    formState: { isValid },
    getValues,
    trigger
  } = useFormContext();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [saveClip, setSaveClip] = useState(false);
  const [saveNewClip, setSaveNewClip] = useState(false);

  const { mutate: newClip, isLoading: isSaving } =
    useAdminLiveStreamStreamIdClipPost();

  const { mutate: editClip, isLoading: isEditing } =
    useAdminLiveStreamStreamIdClipClipIdPut();

  const saveHandler = async (type: "NEW" | "EXISTING") => {
    const values = getValues();
    if (type === "NEW")
      newClip(
        {
          streamId: streamDetails.streamId,
          data: {
            title: values.title,
            tags: values?.tags && values?.tags?.map((tag) => tag.value),
            albumId: values?.album?.value || undefined,
            position: streamDetails.endTime,
            duration:
              (streamDetails?.endTime || 0) - (streamDetails?.startTime || 0)
          }
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Clip created successfully", {
              variant: "success"
            });
            onClose();
          }
        }
      );
    else {
      editClip(
        {
          streamId: streamDetails.streamId,
          clipId: streamDetails.clipId!,
          data: {
            isReuploadRequired: true,
            position: streamDetails.endTime,
            duration:
              (streamDetails?.endTime || 0) - (streamDetails?.startTime || 0),
            metadata: {
              title: values.title,
              duration:
                (streamDetails?.endTime || 0) - (streamDetails?.startTime || 0)
            }
          }
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Clip cropped successfully", {
              variant: "success"
            });
            onClose();
          },
          onError: () => {
            enqueueSnackbar("Error cropping clip", {
              variant: "error"
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    trigger();
  }, []);

  return (
    <div
      style={{
        minHeight: "415px",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography style={{ fontSize: "18px", fontWeight: 700 }}>
            Crop Clip
          </Typography>
        </Grid>
        <Grid xs={12}>
          <FormInput
            control={control}
            type="text"
            name="title"
            label="Title"
            required
            rules={{
              required: "Title is required"
            }}
            disabled
          />
        </Grid>

        <ClipTimeFields
          time={{
            startTime: streamDetails?.startTime as number,
            maxTime: streamDetails.duration,
            endTime: streamDetails?.endTime as number
          }}
          setClipData={setClipData}
          playerRef={playerRef}
        />

        <Grid
          xs={12}
          sx={{
            "&:focus-visible": {
              outline: "none"
            }
          }}
        >
          <VideoFrames
            details={streamDetails}
            onIndicatorMove={(startTime, endTime) => {
              const formatedStartTime = Number(startTime.toFixed(0));
              const formattedEndTime = Number(endTime.toFixed(0));
              setClipData((prev) => ({
                ...prev,
                startTime: formatedStartTime,
                endTime: formattedEndTime
              }));
            }}
            playerRef={playerRef}
            setIsDraggingBox={setIsDragging}
          />
        </Grid>

        <Grid
          xs={12}
          container
          gap="8px"
          marginTop="16px"
          justifyContent="flex-end"
        >
          <Button
            variant="admin-secondary"
            onClick={() => setOpenCancelDialog(true)}
          >
            Cancel
          </Button>
          <Button
            variant="admin-primary"
            disabled={!isValid}
            onClick={() => setSaveClip(true)}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <ConfirmationDialog
        title="Discard crop?"
        body="Are you sure you want discard your crop changes made to this clip?"
        open={openCancelDialog}
        hideCloseIcon
        close={() => setOpenCancelDialog(false)}
        onCancel={() => {
          onClose();
        }}
        onConfirm={() => setOpenCancelDialog(false)}
        cancelBtnText="Discard Crop"
        confirmBtnText="Keep"
      />
      <ConfirmationDialog
        title="Save Clip?"
        body="Save changes made to this clip or save as a new clip?"
        open={saveClip}
        onCancel={() => setSaveClip(false)}
        onConfirm={() => saveHandler("EXISTING")}
        cancelBtnText="Cancel"
        confirmBtnText="Save Clip"
        isConfirming={isEditing}
        additionalBtn={
          <StyledButton>
            <Button
              variant="admin-secondary"
              onClick={() => {
                setSaveClip(false);
                setSaveNewClip(true);
              }}
            >
              Save as New Clip
            </Button>
          </StyledButton>
        }
      />
      {saveNewClip && (
        <SaveNewClipModal
          onSave={() => saveHandler("NEW")}
          onClose={() => setSaveNewClip(false)}
          isLoading={isSaving}
          streamDetails={streamDetails}
        />
      )}
    </div>
  );
};
