import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminFaqFaqIdGet,
  useAdminFaqFaqIdPut,
  useAdminHelpCategoryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeFirstCharacter } from "@utils/capitalize";
import { DISPLAY_CHANNEL_OPTIONS, RELATED_TO_OPTIONS } from "@utils/constants";
import { useSnackbar } from "notistack";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export const FaqEdit = () => {
  const navigate = useNavigate();
  const { faqId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    reset,
    setValue
  } = useForm({
    mode: "onBlur"
  });

  const { data: faq, isLoading: faqLoading } = useAdminFaqFaqIdGet(faqId!);
  const { data: categories, isLoading: categoriesLoading } =
    useAdminHelpCategoryGet();
  const categoriesOptions = categories?.data.helpCategory?.map((category) => ({
    label: category.name!,
    value: category.helpCategoryId
  }));
  useEffect(() => {
    if (faq?.data) {
      reset({
        question: faq.data.question,
        answer: faq.data.answer,
        categoryId: faq.data.categoryId,
        relatedTo: faq.data.relatedTo,
        displayChannel: faq.data.displayChannel
      });
    }
  }, [faq]);

  const { mutate: save, isLoading: isSaving } = useAdminFaqFaqIdPut();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues
      };
      values["question"] = values["question"]
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      save(
        {
          faqId: faqId!,
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Faq edited successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
            } else {
              navigate(`/faqs/${faqId}`);
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to edit faq!", {
              variant: "error"
            });
          }
        }
      );
    };

  return (
    <Container>
      <Toolbar title="Edit FAQ" />
      <Loader isLoading={faqLoading}>
        <Form>
          <Grid data-testid="faq-add-form" container spacing={3}>
            <Grid data-testid="faq-question" xs={12} md={6}>
              <FormInput
                control={control}
                name="question"
                type="text"
                label="Question"
                required={true}
                rules={{
                  required: "Question is required"
                }}
                onChange={(e) => {
                  setValue(
                    "question",
                    capitalizeFirstCharacter(
                      (e as ChangeEvent<HTMLInputElement>).target.value
                    )
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginTop: "24px" }}>
            <Grid data-testid="faq-answer" xs={12} md={12}>
              <FormInput
                control={control}
                name="answer"
                type="text"
                label="Answer"
                multiline
                rows={4}
                required={true}
                rules={{
                  required: "Answer is required"
                }}
              />
            </Grid>
            <Grid data-testid="faq-category" xs={12} md={6}>
              <FormSelect
                options={categoriesOptions || []}
                isLoading={categoriesLoading}
                control={control}
                name="categoryId"
                type="text"
                label="Category"
                required={true}
                rules={{
                  required: "Category is required"
                }}
              />
            </Grid>
            <Grid data-testid="faq-relatedTo" xs={12} md={6}>
              <FormSelect
                options={RELATED_TO_OPTIONS}
                control={control}
                name="relatedTo"
                type="text"
                label="Related To"
                required={true}
                rules={{
                  required: "Related To is required"
                }}
              />
            </Grid>
            <Grid data-testid="faq-displayChannel" xs={12} md={6}>
              <FormMultiSelect
                options={DISPLAY_CHANNEL_OPTIONS}
                control={control}
                name="displayChannel"
                label="Display Channel"
                required={true}
                rules={{
                  required: "Display Channels To is required"
                }}
              />
            </Grid>
          </Grid>
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        isDisabled={!isValid || isSaving || !isDirty}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/faqs")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
